import { render, staticRenderFns } from "./TakipList.vue?vue&type=template&id=c0b8e71c&scoped=true"
import script from "./TakipList.vue?vue&type=script&lang=ts"
export * from "./TakipList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0b8e71c",
  null
  
)

export default component.exports